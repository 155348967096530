@font-face {
  font-family: "Honeymoon";
  font-style: normal;
  src: url(../fonts/Honeymoon.otf) format("opentype");
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Honeymoon";
}

.timer-section {
  margin: 0 10px;
  text-align: center;
  color: #737573;
}

.timer-number {
  font-size: 3em;
  font-weight: bold;
  color: #737573;
}

.timer-label {
  font-size: 1em;
  color: #8a7f8d;
}


@media (max-width: 1440px) and (max-height: 2960px) {

  
  .timer-number {
    font-size: 2em;
    font-weight: bold;
    color: #737573;
  }
  
  .timer-label {
    font-size: 1em;
    color: #8a7f8d;
  }  


}