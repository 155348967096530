/* General Styles */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  src: url(./fonts/PlayfairDisplay-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Dancing Script";
  font-style: normal;
  src: url(./fonts/DancingScript-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Honeymoon";
  font-style: normal;
  src: url(./fonts/Honeymoon.otf) format("opentype");
}

.app {
  background-image: url("./images/fondo_5.png");
  background-size: auto;
  background-repeat: repeat;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  text-align: center;
  padding-bottom: 5%;
}

.section h1 {
  font-family: "Honeymoon";
  font-weight: bold;
  font-size: 4em;
  text-align: center;
  margin-bottom: 20px;
  color: #a3c9b8;
}

.section p {
  font-family: "Honeymoon";
  font-size: 2em;
  font-weight: normal;
  margin-bottom: 20px;
  color: #737573;
  margin: 20px 0;
}

/* Section Specific Styles */
.section.section1 {
  height: 50%;
}

.centered-logo {
  max-width: 50%;
  max-height: 100%;
  padding-bottom: 10%;
}

.centered-logo-bottom {
  max-width: 50%;
  max-height: 50%;
}

.fecha {
  max-width: 50%;
  max-height: 100%;
}

.divisor {
  max-width: 50%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
  align-items: center;
}

/* Timer Styles */
.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
}

.timer-section {
  margin: 0 10px;
  text-align: center;
}

.timer-number {
  font-size: 2em;
  font-weight: bold;
}

.timer-label {
  font-size: 1em;
  color: #8a7f8d;
}

.h1-bottom {
  color: #f8c0bb !important;
}

@media (max-width: 1440px) and (max-height: 2960px) {
  .section.section1 {
    padding: 0;
    margin: 0;
  }

  .centered-logo {
    padding-top: 50%;
    padding-bottom: 50%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .centered-logo-bottom {
    padding-top: 10%;
    padding-bottom: 10%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .fecha {
    max-width: 120%;
    max-height: 120%;
    padding-bottom: 10%;
  }

  .divisor {
    padding-top: 30%;
    padding-bottom: 30%;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
  }

  .section h1 {
    font-size: 3em;
    margin-left: 30px;
    margin-right: 30px;
  }

  .section p {
    margin-left: 20px;
    margin-right: 20px;
  }
}
